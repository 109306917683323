import React, {useEffect, useState} from 'react'
import {mdiBug} from '@mdi/js'
import PrimaryButton from 'src/components/elements/Buttons/PrimaryButton/PrimaryButton'
import TableCustom, {PaginationType} from 'src/components/elements/TableCustom/TableCustom'
import Filters, {FilterItem} from 'src/components/templates/Filters/Filters'
import PatientEventModal from 'src/components/templates/CalendarDialog/CalendarDialog'
import VirtualMeetingDetail from 'src/components/elements/VirtualMeetingsDetail/VirtualMeetingDetail'
import {Modal, Snackbar, Typography} from '@mui/material'
import DialogCustom from 'src/components/elements/DialogCustom/DialogCustom'
import SecondaryButton from 'src/components/elements/Buttons/SecondaryButton/SecondaryButton'
import ActivityDialog from 'src/components/templates/ActivityDialog/ActivityDialog'
import apiClient from 'src/services/apiClient'
import {STATUS} from 'src/constants/constants'
import SnackbarAlertCustom from 'src/components/elements/SnackbarAlertCustom/SnackbarAlertCustom'
import {useAppSelector} from 'src/hooks'

const COLUMNS = [
  {
    id: 'plannedDate',
    label: 'Data inizio',
    type: 'date',
    format: 'DD MMMM YYYY HH:mm',
    noSort: true
  },
  {id: 'title', label: 'Titolo', noSort: true},
  {id: 'type', label: 'Tipo', noSort: true}
]

const userEventsTypes = [
  {
    label: 'Attività',
    value: 'USER_ACTIVITY'
  },
  {
    label: 'Evento Paziente',
    value: 'PERSONAL_EVENT'
  },
  {
    label: 'Incontro virtuale',
    value: 'VIRTUAL_MEETING'
  }
]

const filterConfig: FilterItem[] = [
  {
    id: 'date',
    label: 'Data',
    type: 'rangePicker'
  },
  {
    id: 'type',
    label: 'Tipo',
    type: 'select',
    options: userEventsTypes
  }
]

const Activities: React.FC<{patientId: string}> = ({patientId}) => {
  const {patientDetailConfiguration: {activitiesConfiguration}} = useAppSelector(state => state.pspConfigReducer.data)

  const [selectedActivity, setSelectedActivity] = useState<any>()
  const [isPatientEventModalOpen, setIsPatientEventModalOpen] = useState(false)
  const [isVirtualActivityModalOpen, setIsVirtualActivityModalOpen] = useState(false)
  const [isActivityModalOpen, setIsActivityModalOpen] = useState(false)
  const [isTestDBSModalOpen, setIsTestDBSModalOpen] = useState(false)
  const [pagination, setPagination] = useState<PaginationType>({
    page: 0,
    rowsPerPage: 10,
    order: '-createdAt'
  })
  const [filters, setFilters] = useState<Record<string, string | string[]>[]>([])
  const [data, setData] = useState<any>([])
  const [count, setCount] = useState<number>(0)

  const [status, setStatus] = useState<any>(undefined)
  const [statusCount, setStatusCount] = useState<any>(undefined)

  const [eventsTypes, setEventsTypes] = useState<Record<string, any>[]>([])
  const [currentPatient, setCurrentPatient] = useState<any>()

  const fetchData = async () => {
    const fetchedData = await apiClient.getPatientsUserEvents(patientId, filters, setStatus, pagination) ?? []
    const parsedData = fetchedData.map((userEvent: any) => {
      return {
        ...userEvent,
        type: userEventsTypes.find((type: any) => type.value === userEvent.type)?.label ?? 'Attività'
      }
    })
    setData(parsedData)
  }

  const fetchDataCount = async () => {
    const fetchedDataCount = await apiClient.getPatientsUserEventsCount(patientId, filters, setStatusCount) ?? []
    setCount(fetchedDataCount)
  }

  const fetchEventReasons = async () => {
    const fetchedData = await apiClient.getUserEventsTypes(setStatus) ?? []
    setEventsTypes(fetchedData)
  }

  const fetchPatient = async (patientIdSelected: string) => {
    const patient = await apiClient.getPatientsUserData(patientIdSelected, setStatus) ?? []
    setCurrentPatient(patient)
  }

  useEffect(() => {
    fetchEventReasons()
    fetchPatient(patientId)
  }, [patientId])

  useEffect(() => {
    fetchData()
    fetchDataCount()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientId, filters, pagination])

  const onActivityClick = (activity: any) => {
    setSelectedActivity(activity)
    switch (activity.type) {
      case 'PERSONAL_EVENT': setIsPatientEventModalOpen(true); return
      case 'VIRTUAL_MEETING': setIsVirtualActivityModalOpen(true); return
      default: setIsActivityModalOpen(true)
    }
  }

  const onSubmit = (values: any) => {
    setFilters(values)
  }

  const onSave = async (values: any) => {
    const {warning} = values
    delete values.warning

    const event = {
      ...values,
      user: values.user?._id ?? values.user,
      channels: values.channels ?? [],
      reminderMilliseconds: warning.value * warning.unit
    }
    await apiClient.postAppointment(event, setStatus)
    fetchData()
    fetchDataCount()
  }

  return (
    <div style={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
      <div style={{display: 'flex', justifyContent: 'flex-end', gap: '8px'}}>
        {activitiesConfiguration.testDBS && <PrimaryButton onClick={() => setIsTestDBSModalOpen(true)}>{'Richiedi test DBS'}</PrimaryButton>}
        {activitiesConfiguration.patientEvent && <PrimaryButton onClick={() => setIsPatientEventModalOpen(true)}>{'Nuovo evento paziente'}</PrimaryButton>}
        <Filters config={filterConfig} onSubmit={onSubmit} />
      </div>
      <TableCustom
        actions={[
          {
            id: 'detail',
            label: 'Dettaglio',
            icon: mdiBug,
            action: onActivityClick
          }
        ]}
        columns={COLUMNS}
        isLoading={status === STATUS.PENDING}
        onPaginationChange={setPagination}
        onRowClick={onActivityClick}
        pagination={pagination}
        rows={data}
        totalElements={count}
      />
      <PatientEventModal
        event={selectedActivity}
        eventsTypes={eventsTypes}
        onClose={() => {
          setIsPatientEventModalOpen(false)
          setSelectedActivity(undefined)
        }}
        onSave={(values) => {
          onSave(values)
          setIsPatientEventModalOpen(false)
        }}
        open={isPatientEventModalOpen}
        patientDetailUser={currentPatient}
      />
      <Modal
        onClose={() => setIsVirtualActivityModalOpen(false)}
        open={isVirtualActivityModalOpen}
      >
        <VirtualMeetingDetail
          event={selectedActivity}
          handleClose={() => setIsVirtualActivityModalOpen(false)}
        />
      </Modal>
      {isActivityModalOpen &&
        <ActivityDialog
          activity={selectedActivity}
          onClose={() => setIsActivityModalOpen(false)}
          showDepartment={false}
          withDocument
        />}
      <DialogCustom
        body={<p>{'Confermi la richiesta di un Test DBS per il paziente?'}</p>}
        footer={
          <div style={{display: 'flex', justifyContent: 'flex-end', gap: '8px'}}>
            <SecondaryButton onClick={() => setIsTestDBSModalOpen(false)}>{'Ok'}</SecondaryButton>
            <SecondaryButton onClick={() => setIsTestDBSModalOpen(false)}>{'Annulla'}</SecondaryButton>
          </div>
        }
        onClose={() => setIsTestDBSModalOpen(false)}
        open={isTestDBSModalOpen}
        title={{text: 'Richiedi Test DBS', format: false}}
      />
      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        onClose={() => { setStatus(undefined); setStatusCount(undefined) }}
        open={status === STATUS.REJECTED || statusCount === STATUS.REJECTED}
      >
        <div>
          <SnackbarAlertCustom onClose={() => { setStatus(undefined); setStatusCount(undefined) }} severity='error' sx={{width: '100%'}}>
            <Typography variant='h5'>
              {'Errore nel caricare i dati.'}
            </Typography>
          </SnackbarAlertCustom>
        </div>
      </Snackbar>
    </div>
  )
}

export default Activities
