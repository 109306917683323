import {mdiArrowBottomLeft, mdiEmailOpen} from '@mdi/js'

export const STYLE = {
  HEADER_HEIGHT: 30,
  SIDEBAR_WIDTH: 140,
  SIDEBAR_MARGIN_HEADER_TOP: 58
}

export const PATHS = {
  AGENDA: '/agenda',
  COMMUNICATIONS: '/communication-messages',
  CUSTOMER_USERS: '/customer-users',
  DIARY: '/diary',
  DOCUMENTS: '/documents',
  DOCUMENTS_AND_MATERIALS: '/documents-and-materials',
  EXPORT: '/exports',
  INFO_MATERIALS: '/info-materials',
  LOGIN: '/login',
  PASSWORD_RECOVERY: '/password-recovery',
  PATIENTS: '/patients',
  PATIENT: '/patients/:id',
  MEDICAL_CENTERS: '/medicalcenters',
  PATIENT_ACTIVITIES: '/patient-activities',
  PATIENT_ENROLLMENT: '/patient-enrollment',
  DIGITAL_ENROLLMENT: '/digital-enrollment',
  PATIENT_DIGITAL_ENROLLMENT: '/digital-enrollment/:id',
  PORTALS: '/portals',
  PROFILE: '/profile',
  QUESTIONNAIRE: '/questionnaire',
  SUMMARY: '/',
  TEST_DBS: '/biological-samples',
  VIRTUAL_MEETING_DETAIL: '/virtual-meeting-detail',
  VIRTUAL_MEETINGS: '/virtual-meetings',
  PDF_SUMMARY: 'pdf-summary',
  PDF_DIARY: 'pdf-diary',
  PERFORMANCES: '/performances',
  AUTO_REGISTRATION: '/self-registration'
}

export const TABLE_ICONS: { [key: string]: string } = {
  mdiEmailOpen: mdiEmailOpen,
  mdiArrowBottomLeft: mdiArrowBottomLeft
}

export const STATUS = {
  PENDING: 'PENDING',
  FULFILLED: 'FULFILLED',
  REJECTED: 'REJECTED'
}

export const PREVIEW_MIME_TYPES = ['application/pdf', 'audio/mpeg', 'video/x-msvideo', 'video/mp4', 'video/mpeg', 'image/jpeg', 'image/png', 'image/svg+xml']

export const docTypesOptions = [
  {
    label: 'Documento PDF',
    value: 'DOCUMENT_PDF'
  },
  {
    label: 'Immagine',
    value: 'IMAGE'
  },
  {
    label: 'Video',
    value: 'VIDEO'
  },
  {
    label: 'Audio',
    value: 'AUDIO'
  },
  {
    label: 'Documento WORD',
    value: 'DOCUMENT_WORD'
  },
  {
    label: 'Documento Excel',
    value: 'DOCUMENT_EXCEL'
  },
  {
    label: 'Presentazione',
    value: 'PRESENTATION'
  },
  {
    label: 'Zip',
    value: 'ZIP'
  },
  {
    label: 'Altro',
    value: 'OTHER'
  }
]

export const statesOptions = [
  {
    value: 'TO_ACTIVE',
    label: 'Da attivare'
  },
  {
    value: 'ACTIVATING',
    label: 'in attivazione'
  },
  {
    value: 'ACTIVE',
    label: 'Attivo'
  },
  {
    value: 'ACTIVE_DELIVERY',
    label: 'Attivo delivery'
  },
  {
    value: 'SUSPENDED',
    label: 'Sospeso'
  },
  {
    value: 'WAITING_TO_EXIT',
    label: 'In attesa di uscita dal psp'
  },
  {
    value: 'EXITED',
    label: 'Uscito'
  }
]

export const THERAPY_DOSAGE_UNIT_TYPE_OPTIONS = [
  {value: 'mg', label: 'mg'}
  // {value: 'ml', label: 'ml'}
  // {value: 'UI', label: 'UI'},
  // {value: 'Fiale', label: 'Fiale'},
  // {value: 'Pillole', label: 'Pillole'},
  // {value: 'Compresse', label: 'Compresse'},
  // {value: 'mg/ml', label: 'mg/ml'},
  // {value: 'mg/Km-WEIGHT', label: 'mg/Km peso'},
  // {value: 'ng/Km-WEIGHT', label: 'ng/Km peso'}
]

export const THERAPY_DAILY_DOSAGE_UNIT_TYPE_OPTIONS = [
  {value: 'ml', label: 'ml'}
  // {value: 'mg', label: 'mg'},
  // {value: 'UI', label: 'UI'},
  // {value: 'Fiale', label: 'Fiale'},
  // {value: 'Pillole', label: 'Pillole'},
  // {value: 'Compresse', label: 'Compresse'},
  // {value: 'mg/ml', label: 'mg/ml'},
  // {value: 'mg/Km-WEIGHT', label: 'mg/Km peso'},
  // {value: 'ng/Km-WEIGHT', label: 'ng/Km peso'}
]

export const THERAPY_SPEED_OF_DEVICE_UNIT_OPTIONS = [
  {value: 'ml/h', label: 'ml/h'}
  // {value: 'ng/kg/min', label: 'ng/kg/min'}
  // {value: '', label: ''},
  // {value: 'ml/min', label: 'ml/min'}
]

export const THERAPY_SPEED_OF_ASSUMPTION_UNIT_OPTIONS = [
  {value: 'ng/kg/min', label: 'ng/kg/min'}
  // {value: 'ml/h', label: 'ml/h'},
  // {value: 'ml/min', label: 'ml/min'}
]

export const familyTypes = {
  PARENT: 'Genitore',
  SON: 'Figlio/a',
  SPOUSE: 'Coniuge',
  OTHER: 'Altro'
}

export const beneficiaryOptions = [
  {value: 'beneficiary', label: 'Titolare'},
  {value: 'parent', label: 'Genitore'},
  {value: 'spouse', label: 'Coniuge'},
  {value: 'son', label: 'Figlio/a'},
  {value: 'other', label: 'Altro'},
  {value: 'twoElementCore', label: 'Nucleo di 2 elementi'},
  {value: 'threeElementCore', label: 'Nucleo di 3 elementi'},
  {value: 'fourElementCore', label: 'Nucleo di 4 elementi'},
  {value: 'fivePlusElementCore', label: 'Nucleo di 5+ elementi'},
  {value: 'product', label: 'Prodotto'}
]
