import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {AxiosInstance, AxiosRequestConfig} from 'axios'
import {STATUS} from 'src/constants/constants'
import {TimeslotProps} from 'src/types/types'

type InitialStateUserInfo = {
  status: string,
  statusPatch: string,
  error: string,
  data: {
    title: string,
    firstName: string,
    lastName: string,
    email: string,
    code: string,
    sex: string,
    userGroup: string,
    nationality: string,
    enrollmentDate: string,
    specialization: string,
    medicalCenterIds: string[],
    medicalCenters: {_id: string, name: string}[],
    medicalCenterId: string,
    medicalCenter:string,
    birthDate: string,
    prescribingDoctorId: string,
    prescribingDoctor: string,
    diseases?: {name: string, description: string}[],
    preferredContacts: string[],
    preferredContactMethods: string[],
    phoneNumber?: string,
    cellPhoneNumber?: string,
    faxNumber?: string,
    skypeId?: string,
    address: {
      country: string,
      city: string,
      region: string,
      province: string,
      address: string,
      streetNumber: string,
      postalCode: string,
      locality?: string
    },
    remindTherapyExpiryTypes: string[],
    delegateId_1: string,
    delegateId_2: string,
    caregiverId: string,
    receiveModuleDate: string,
    privacyConsents: string[],
    username: string,
    avatarId: string,
    createdAt: string,
    statesTransitions: string,
    authUserId: string,
    _id: string,
    state: string,
    serviceConf: string,
    drugDeliveryService: boolean,
    unreadNotificationsCount?: number,
    avatarNickName: string,
    loginsCount: number,
    familyMembers?: string[],
    preferredTimeSlots?: TimeslotProps[]
  }
}

export const initialState : InitialStateUserInfo = {
  data: {
    firstName: '',
    lastName: '',
    userGroup: '',
    username: '',
    avatarId: '',
    title: '',
    email: '',
    sex: '',
    nationality: '',
    enrollmentDate: '',
    specialization: '',
    medicalCenterIds: [],
    medicalCenters: [],
    medicalCenterId: '',
    medicalCenter: '',
    birthDate: '',
    prescribingDoctorId: '',
    diseases: [],
    preferredContacts: [],
    phoneNumber: '',
    cellPhoneNumber: '',
    faxNumber: '',
    skypeId: '',
    privacyConsents: [],
    caregiverId: '',
    prescribingDoctor: '',
    preferredContactMethods: [],
    receiveModuleDate: '',
    remindTherapyExpiryTypes: [],
    address: {
      city: '',
      region: '',
      province: '',
      address: '',
      streetNumber: '',
      postalCode: '',
      locality: '',
      country: ''
    },
    createdAt: '',
    delegateId_1: '',
    delegateId_2: '',
    statesTransitions: '',
    authUserId: '',
    _id: '',
    state: '',
    serviceConf: '',
    drugDeliveryService: false,
    code: '',
    avatarNickName: '',
    loginsCount: 0,
    familyMembers: [],
    preferredTimeSlots: []
  },
  error: '',
  status: '',
  statusPatch: ''
}

export const fetchUserInfo = createAsyncThunk('profile/fetchUserInfo', async ({httpClient, config} : {httpClient: AxiosInstance, config: AxiosRequestConfig}) => {
  return await httpClient.request(config)
})

export const patchUserData = createAsyncThunk('profile/patchUserData', async ({httpClient, config} : {httpClient: AxiosInstance, config: AxiosRequestConfig}) => {
  return await httpClient.request(config)
})

export const userSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    clearUserInfo: () => initialState,
    setUnreadNotificationsCount: (state, action) => ({
      ...state,
      data: {...state.data, unreadNotificationsCount: action.payload}
    }),
    restoreUserStatusPatch: (state) => ({...state, statusPatch: ''})
  },
  extraReducers: builder => {
    builder.addCase(fetchUserInfo.pending, state => {
      state.status = STATUS.PENDING
    })
    builder.addCase(fetchUserInfo.fulfilled, (state, action: any) => {
      state.data = {...initialState.data, ...action.payload.data}
      state.error = ''
      state.status = STATUS.FULFILLED
    })
    builder.addCase(fetchUserInfo.rejected, (state, action) => {
      state.status = STATUS.REJECTED
    })
    builder.addCase(patchUserData.pending, state => {
      state.statusPatch = STATUS.PENDING
    })
    builder.addCase(patchUserData.fulfilled, (state, action: any) => {
      state.data = {...state.data, ...action.payload.data}
      state.error = ''
      state.statusPatch = STATUS.FULFILLED
    })
    builder.addCase(patchUserData.rejected, (state, action) => {
      state.statusPatch = STATUS.REJECTED
    })
  }
})

export const {clearUserInfo, setUnreadNotificationsCount, restoreUserStatusPatch} = userSlice.actions

export default userSlice.reducer
