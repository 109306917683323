import React, {ReactNode, useCallback, useState} from 'react'
import {useLocation} from 'react-router-dom'
import Icon from '@mdi/react'
import {mdiChartPie, mdiAccountGroup, mdiChatQuestionOutline, mdiAccountMultiplePlus, mdiCalendar, mdiAccountSearch, mdiPhoneIncoming, mdiFormatListBulleted, mdiMonitorAccount, mdiChatProcessing, mdiFolderAccountOutline, mdiFileDocumentMultipleOutline, mdiAccount, mdiDomain, mdiNotebook, mdiTimeline, mdiDownload} from '@mdi/js'

import NavbarItem, {MenuItem} from '../../elements/NavbarItem/NavbarItem'
import {List} from '@mui/material'
import {NavBarStyled} from './NavBarStyled'
import {PATHS} from 'src/constants/constants'
import {useAppSelector} from 'src/hooks'
import ContactMeDialog from '../ContactMeDialog/ContactMeDialog'
import {Space} from 'antd'

const CustomBadge: React.FC<{children: ReactNode}> = ({children}) => (
  <div style={{backgroundColor: '#01b0f1', color: 'white', padding: '2px 5px', borderRadius: '10px', fontSize: '11px'}}>{children}</div>
)

const CustomIcon: React.FC<{path: string}> = ({path}) => {
  return <Icon color='white' path={path} size='24px' />
}

type NavBarProps = {
  setContactStatus: any;
  bottomLogo: any
};

const NavBar: React.FC<NavBarProps> = ({setContactStatus, bottomLogo}) => {
  const {pathname} = useLocation()
  const sidebarConfig = useAppSelector(state => state.pspConfigReducer.data.sidebarItems)
  const {unreadNotificationsCount = 0} = useAppSelector(state => state.userReducer.data)

  const isSelected = useCallback((currentPage) => {
    return pathname === currentPage
  }, [pathname])
  const [isContactMeDialog, setIsContactMeDialog] = useState<boolean>(false)

  const menuItems = [
    {
      link: PATHS.SUMMARY,
      key: 'summary',
      name: sidebarConfig.summary.label,
      selected: isSelected(PATHS.SUMMARY),
      icon: <CustomIcon path={mdiChartPie} />
    },
    {
      link: PATHS.SUMMARY,
      key: 'summaryInsurance',
      name: sidebarConfig.summaryInsurance?.label,
      selected: isSelected(PATHS.SUMMARY),
      icon: <CustomIcon path={mdiTimeline} />
    },
    {
      link: PATHS.SUMMARY,
      key: 'summaryPatient',
      name: sidebarConfig.summaryPatient.label,
      selected: isSelected(PATHS.SUMMARY),
      icon: <CustomIcon path={mdiTimeline} />
    },
    {
      link: PATHS.PATIENTS,
      key: 'patients',
      name: sidebarConfig.patients.label,
      selected: isSelected(PATHS.PATIENTS),
      icon: <CustomIcon path={mdiAccountGroup} />
    },
    {
      link: PATHS.PATIENT_ENROLLMENT,
      key: 'patientEnrollment',
      name: sidebarConfig.patientEnrollment.label,
      selected: isSelected(PATHS.PATIENT_ENROLLMENT),
      icon: <CustomIcon path={mdiAccountMultiplePlus} />
    },
    {
      link: PATHS.DIGITAL_ENROLLMENT,
      key: 'digitalEnrollment',
      name: sidebarConfig.digitalEnrollment?.label,
      selected: isSelected(PATHS.DIGITAL_ENROLLMENT),
      icon: <CustomIcon path={mdiAccountMultiplePlus} />
    },
    {
      link: PATHS.AGENDA,
      key: 'agenda',
      name: sidebarConfig.agenda.label,
      selected: isSelected(PATHS.AGENDA),
      icon: <CustomIcon path={mdiCalendar} />
    },
    {
      link: PATHS.DIARY,
      key: 'therapyDiary',
      name: sidebarConfig.therapyDiary.label,
      selected: isSelected(PATHS.DIARY),
      icon: <CustomIcon path={mdiAccountGroup} />
    },
    {
      link: PATHS.PERFORMANCES,
      key: 'performances',
      name: sidebarConfig.performances?.label,
      selected: isSelected(PATHS.PERFORMANCES),
      icon: <CustomIcon path={mdiAccountGroup} />
    },
    {
      link: PATHS.PATIENT_ACTIVITIES,
      key: 'patientActivities',
      name: sidebarConfig.patientActivities.label,
      selected: isSelected(PATHS.PATIENT_ACTIVITIES),
      icon: <CustomIcon path={mdiAccountSearch} />
    },
    {
      link: PATHS.EXPORT,
      key: 'export',
      name: sidebarConfig.export.label,
      selected: isSelected(PATHS.EXPORT),
      icon: <CustomIcon path={mdiNotebook} />
    },
    {
      link: PATHS.VIRTUAL_MEETINGS,
      key: 'virtualMeetings',
      name: sidebarConfig.virtualMeetings.label,
      selected: isSelected(PATHS.VIRTUAL_MEETINGS),
      icon: <CustomIcon path={mdiMonitorAccount} />
    },
    {
      link: PATHS.TEST_DBS,
      key: 'biologicalSamples',
      name: sidebarConfig.biologicalSamples?.label,
      selected: isSelected(PATHS.TEST_DBS),
      icon: <CustomIcon path={mdiFormatListBulleted} />
    },
    {
      link: PATHS.COMMUNICATIONS,
      key: 'communications',
      name: (
        <Space size='middle'>
          {sidebarConfig.communications.label}
          <CustomBadge>{unreadNotificationsCount}</CustomBadge>
        </Space>
      ),
      selected: isSelected(PATHS.COMMUNICATIONS),
      icon: <CustomIcon path={mdiChatProcessing} />
    },
    {
      key: 'contact',
      name: sidebarConfig.contact.label,
      selected: false,
      icon: <CustomIcon path={mdiPhoneIncoming} />,
      onClick: () => setIsContactMeDialog(true)
    },
    {
      link: PATHS.INFO_MATERIALS,
      key: 'infoMaterials',
      name: sidebarConfig.infoMaterials.label,
      selected: isSelected(PATHS.INFO_MATERIALS),
      icon: <CustomIcon path={mdiFolderAccountOutline} />
    },
    {
      link: PATHS.DOCUMENTS,
      key: 'documents',
      name: sidebarConfig.documents.label,
      selected: isSelected(PATHS.DOCUMENTS),
      icon: <CustomIcon path={mdiFileDocumentMultipleOutline} />
    },
    {
      link: PATHS.DOCUMENTS_AND_MATERIALS,
      key: 'documentsAndInfoMaterials',
      name: sidebarConfig.documentsAndInfoMaterials.label,
      selected: isSelected(PATHS.DOCUMENTS_AND_MATERIALS),
      icon: <CustomIcon path={mdiDownload} />
    },
    {
      link: PATHS.MEDICAL_CENTERS,
      key: 'medicalCenters',
      name: sidebarConfig.medicalCenters.label,
      selected: isSelected(PATHS.MEDICAL_CENTERS),
      icon: <CustomIcon path={mdiDomain} />
    },
    {
      link: PATHS.CUSTOMER_USERS,
      key: 'customerUtilities',
      name: sidebarConfig.customerUtilities.label,
      selected: isSelected(PATHS.CUSTOMER_USERS),
      icon: <CustomIcon path={mdiAccount} />
    },
    {
      link: PATHS.QUESTIONNAIRE,
      key: 'survey',
      name: sidebarConfig.survey.label,
      selected: isSelected(PATHS.QUESTIONNAIRE),
      icon: <CustomIcon path={mdiChatQuestionOutline} />
    }
  ]

  const itemsMapper = function mapper (menuItem: MenuItem) {
    const {key} = menuItem

    return (
      <NavbarItem key={key} menuItem={menuItem} />
    )
  }

  return (
    <NavBarStyled>
      <List>
        {menuItems.filter((item) => sidebarConfig[item.key]?.show).map(itemsMapper)}
      </List>
      {isContactMeDialog &&
        <ContactMeDialog onClose={() => { setIsContactMeDialog(false) }} setStatus={setContactStatus} />}
      {bottomLogo &&
        <div className='nav-bar-bottom-logo' style={{zIndex: 15}}>
          <img alt='menu-logo' height={50} src={`data:image/png;base64,${bottomLogo}`} width={150} />
        </div>}
    </NavBarStyled>
  )
}

export default NavBar
