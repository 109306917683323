import {mdiCalendarBlank, mdiDownload, mdiFolderOutline, mdiPhone} from '@mdi/js'
import Icon from '@mdi/react'
import {InsuranceUserSummaryStyled} from './InsuranceUserSummaryStyled'
import {useAppSelector} from 'src/hooks'
import {PATHS, STATUS} from 'src/constants/constants'
import LayoutContainer from 'src/components/layouts/LayoutContainer/LayoutContainer'
import {useHistory} from 'react-router-dom'
import HeaderSummaryCard from 'src/components/templates/HeaderSummaryCard/HeaderSummaryCard'
import EmptyPlaceholderSummaryCard from 'src/components/templates/EmptyPlaceholderSummaryCard copy/EmptyPlaceholderSummaryCard'
import apiClient from 'src/services/apiClient'
import {useEffect, useState} from 'react'
import {Button, Snackbar, Typography} from '@mui/material'
import SnackbarAlertCustom from 'src/components/elements/SnackbarAlertCustom/SnackbarAlertCustom'
import moment from 'moment'
import {getIconState} from 'src/utils/performances'
import Loader from 'src/components/elements/Loader/Loader'
import ReactHtmlParser from 'react-html-parser'

function InsuranceUserSummary () {
  const [statusDocuments, setStatusDocuments] = useState<any>(undefined)
  const [statusDownload, setStatusDownload] = useState<any>(undefined)
  const [documents, setDocuments] = useState<any>([])
  const [performances, setPerfomances] = useState<any>([])
  const [statusPerformances, setStatusPerformances] = useState<any>(undefined)
  const {summaryInsuranceConfiguration} = useAppSelector(state => state.pspConfigReducer.data)

  const userInfo = useAppSelector(({userReducer}) => userReducer.data)

  const history = useHistory()

  const fetchData = async () => {
    const fetchedData = await apiClient.getDocuments([], setStatusDocuments, {
      page: 0,
      rowsPerPage: 2,
      order: '-createdAt'
    }) ?? []
    setDocuments(fetchedData)
  }

  const fetchPerformances = async () => {
    const fetchedData = await apiClient.getUserPerformances(setStatusPerformances, {
      page: 0,
      rowsPerPage: 2,
      order: ''
    })
    setPerfomances(fetchedData)
  }

  useEffect(() => {
    fetchData()
    fetchPerformances()
  }, [])

  const dowloadFile = async (documentData: any) => {
    const fileResponse = await apiClient.download(documentData?.fileId, setStatusDownload)
    const blob = new Blob([new Uint8Array(fileResponse?.data)], {type: fileResponse?.headers['content-type']})
    const filepath = (window.URL || window.webkitURL).createObjectURL(blob)
    const link = document.createElement('a')
    link.href = filepath
    link.setAttribute('download', documentData?.fileName)
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const performancesCard = ((performances && performances.length) ? (
    <div style={{display: 'flex', flexDirection: 'column', gap: '18px'}}>
      {performances.map((perfomance: any, index: number) =>
        (
          <div
            key={index} style={{
              border: '1px solid lightgray',
              borderLeft: '3px solid #afbb00',
              borderRadius: '8px',
              width: '100%',
              padding: '24px',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <div><b>{perfomance?.title}</b></div>
              <div>{moment(perfomance?.date).format('DD/MM/YYYY')}</div>
            </div>

            <div style={{display: 'flex', justifyContent: 'space-between', gap: '15px'}}>
              <b>{perfomance?.state}</b>
              {getIconState(perfomance?.state)}
            </div>
          </div>
        ))}
    </div>
  ) : (
    <EmptyPlaceholderSummaryCard
      icon={mdiCalendarBlank}
      subtitle={'Verrai contattato dalla tua struttura per programmare una prestazione'}
      title={'Nessuna prestazione in programma'}
    />
  ))

  const documentsCard = ((documents && documents.length) ? (
    <div style={{display: 'flex', flexDirection: 'column', gap: '18px'}}>
      {documents.map((document: any, index: number) =>
        (
          <div
            key={index} style={{
              border: '1px solid lightgray',
              borderLeft: '3px solid #afbb00',
              borderRadius: '8px',
              width: '100%',
              padding: '24px',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <div>
              <div><b>{document?.fileName}</b></div>
              <div>{moment(document?.createdAt).format('DD/MM/YYYY')}</div>
            </div>
            <Button
              onClick={() => { dowloadFile(document) }}
              variant='text'
            >
              <div style={{display: 'flex'}}>
                <Icon path={mdiDownload} size={1} style={{alignSelf: 'center'}} />
                <b>{'SCARICA'}</b>
              </div>
            </Button>
          </div>
        ))}
    </div>
  ) : (
    <EmptyPlaceholderSummaryCard
      icon={mdiFolderOutline}
      subtitle={'Non è stato caricato ancora nessun documento'}
      title={'Nessun documento caricato'}
    />
  ))

  return (

    <LayoutContainer breadcrumbs={[{label: '', href: PATHS.SUMMARY}]}>
      <InsuranceUserSummaryStyled>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <div style={{fontWeight: 600, fontSize: '32px'}}>{`Ciao ${userInfo?.firstName} 👋`}</div>
          <div style={{color: '#afbb00', border: '4px dotted #afbb00', borderRadius: '16px', textAlign: 'center', padding: '18px', fontSize: '18px', width: '550px'}}>
            <div style={{display: 'flex', gap: '8px', justifyContent: 'center', fontSize: '32px', fontWeight: 600}}>
              <Icon path={mdiPhone} size={2} />
              <div>{summaryInsuranceConfiguration?.greenNumber}</div>
            </div>
            <div>{summaryInsuranceConfiguration?.greenNumberDescription}</div>
          </div>
        </div>
        <div style={{display: 'flex'}}>
          <div style={{width: '40%', alignSelf: 'center'}}>
            <img alt='Homepage doctor' src={'/assets/images/InsuranceOnlineDoctor.svg'} />
          </div>
          {ReactHtmlParser(summaryInsuranceConfiguration?.content)}
        </div>
        <div style={{display: 'flex', gap: '32px'}}>
          <div style={{width: '100%'}}>
            <HeaderSummaryCard
              onSeeOthers={() => history.push(PATHS.PERFORMANCES)}
              title={'Prestazioni'}
            />
            {statusPerformances === STATUS.PENDING ? <Loader /> : performancesCard}
          </div>
          <div style={{width: '100%'}}>
            <HeaderSummaryCard
              onSeeOthers={() => history.push(PATHS.DOCUMENTS)}
              title={'Ultimi documenti'}
            />
            {statusDocuments === STATUS.PENDING ? <Loader /> : documentsCard}
          </div>
        </div>
      </InsuranceUserSummaryStyled>
      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        onClose={() => { setStatusDocuments(undefined) }}
        open={statusDocuments === STATUS.REJECTED}
      >
        <div>
          <SnackbarAlertCustom onClose={() => { setStatusDocuments(undefined) }} severity='error' sx={{width: '100%'}}>
            <Typography variant='h5'>
              {'Errore nel recuperare i documenti.'}
            </Typography>
          </SnackbarAlertCustom>
        </div>
      </Snackbar>
      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        onClose={() => { setStatusPerformances(undefined) }}
        open={statusPerformances === STATUS.REJECTED}
      >
        <div>
          <SnackbarAlertCustom onClose={() => { setStatusPerformances(undefined) }} severity='error' sx={{width: '100%'}}>
            <Typography variant='h5'>
              {'Errore nel recuperare le prestazioni.'}
            </Typography>
          </SnackbarAlertCustom>
        </div>
      </Snackbar>
      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        onClose={() => setStatusDownload(undefined)}
        open={statusDownload === STATUS.REJECTED}
      >
        <div>
          <SnackbarAlertCustom onClose={() => setStatusDownload(undefined)} severity='error' sx={{width: '100%'}}>
            <Typography variant='h5'>
              {'Errore nel scaricare il documento.'}
            </Typography>
          </SnackbarAlertCustom>
        </div>
      </Snackbar>
    </LayoutContainer>

  )
}

export default InsuranceUserSummary
