import {Snackbar, Typography} from '@mui/material'
import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {FormattedMessage} from 'react-intl'
import PrimaryButton from 'src/components/elements/Buttons/PrimaryButton/PrimaryButton'
import TableCustom, {PaginationType} from 'src/components/elements/TableCustom/TableCustom'
import SnackbarAlertCustom from 'src/components/elements/SnackbarAlertCustom/SnackbarAlertCustom'

import LayoutContainer from 'src/components/layouts/LayoutContainer/LayoutContainer'
import DBSDIalog from 'src/components/templates/DBSDialog/DBSDIalog'
import Filters from 'src/components/templates/Filters/Filters'
import {PATHS, STATUS} from 'src/constants/constants'
import apiClient from 'src/services/apiClient'
import {useAppSelector} from 'src/hooks'

const TestsDBS = () => {
  const stateOptions = [
    {
      value: 'PLANNING',
      label: 'In pianificazione'
    },
    {
      value: 'PLANNED',
      label: 'Pianificato'
    },
    {
      value: 'SAMPLING_OCCURRED',
      label: 'Effettuato'
    },
    {
      value: 'SAMPLING_NOT_OCCURRED',
      label: 'Non effettuato'
    },
    {
      value: 'PICKED_UP',
      label: 'Test ritirato'
    },
    {
      value: 'NOT_PICKED_UP',
      label: 'Test non ritirato'
    },
    {
      value: 'DELETED',
      label: 'Cancellato'
    }
  ]

  const [filters, setFilters] = useState<Record<string, any>[]>([])
  const [isDBSModal, setIsDBSModal] = useState<boolean>(false)
  const [data, setData] = useState<Record<string, any>[]>([])
  const [status, setStatus] = useState<string>()
  const [pagination, setPagination] = useState<PaginationType>({
    page: 0,
    rowsPerPage: 10,
    order: ''
  })
  const [count, setCount] = useState<number>(0)
  const [statusCount, setStatusCount] = useState<string>()
  const configuration = useAppSelector(state => state.pspConfigReducer.data.biologicalSamplesConfiguration)

  useEffect(() => {
    const fetchData = async () => {
      const fetchedData = await apiClient.getTestDbs(filters, setStatus, pagination) ?? []
      setData(fetchedData)
      const fetchedDataCount = await apiClient.getTestDbsCount(filters, setStatusCount) ?? []
      setCount(fetchedDataCount)
    }

    fetchData()
  }, [filters, pagination])

  const onSubmit = (values: any) => {
    setFilters(values)
  }

  const onDBSRequestConfirm = async (patient?: Record<string, any>) => {
    await apiClient.postTestDbs(patient?._id, setStatus)
    const fetchedData = await apiClient.getTestDbs(filters, setStatus, pagination) ?? []
    setData(fetchedData)
    const fetchedDataCount = await apiClient.getTestDbsCount(filters, setStatus) ?? []
    setCount(fetchedDataCount)
    setIsDBSModal(false)
  }

  const cleanData = (rawData: Record<string, any>[]) => {
    return rawData.map((row) => {
      const sampleData: any = row.samples && row.samples[0]
      return {
        _id: row._id,
        patient: `${row.patientFirstName} ${row.patientLastName}`,
        date: row.date ? moment(row.date).format('ddd DD MMM YYYY HH:mm') : undefined,
        status: stateOptions.find((options) => options.value === row.status)?.label,
        paperCardSerialNumber: row.paperCardSerialNumber,
        readableSample: sampleData && `${sampleData.label}: ${sampleData.value}`
      }
    }
    )
  }

  return (
    <LayoutContainer
      breadcrumbs={[{label: configuration?.title, href: PATHS.TEST_DBS}]}
      buttons={[
        <PrimaryButton key='request' onClick={() => setIsDBSModal(true)}>
          <Typography style={{fontSize: 12, fontWeight: 600}}>
            {`Richiedi ${configuration?.subject}`}
          </Typography>
        </PrimaryButton>,
        <Filters config={configuration?.filters} key='filters' onSubmit={onSubmit} />
      ]}
    >
      <div style={{marginTop: '16px'}}>
        <TableCustom
          columns={configuration?.columns}
          onPaginationChange={setPagination}
          pagination={pagination}
          rows={cleanData(data)}
          totalElements={count}
        />
        {isDBSModal &&
          <DBSDIalog
            onClose={() => setIsDBSModal(false)}
            onConfirm={onDBSRequestConfirm}
          />}
      </div>
      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        autoHideDuration={2000}
        onClose={() => { setStatus(undefined); setStatusCount(undefined) }}
        open={status === STATUS.REJECTED || statusCount === STATUS.REJECTED}
      >
        <div>
          <SnackbarAlertCustom
            onClose={() => { setStatus(undefined); setStatusCount(undefined) }}
            severity='error'
            sx={{width: '100%'}}
          >
            <Typography variant='h5'>
              <FormattedMessage id='testDBSWarning' />
            </Typography>
          </SnackbarAlertCustom>
        </div>
      </Snackbar>
    </LayoutContainer>
  )
}

export default TestsDBS
