import DialogCustom from 'src/components/elements/DialogCustom/DialogCustom'
import {CaregiverDetailDialogStyled} from './CaregiverDetailDialogStyled'
import {Divider, Typography} from '@mui/material'
import {FormattedMessage} from 'react-intl'
import {Form, Input, Select} from 'antd'
import {useForm} from 'antd/lib/form/Form'
import {useEffect} from 'react'
import {ITALIAN_PROVINCE} from 'src/constants/mock/province'
import {ITALIAN_REGIONS} from 'src/constants/mock/county'
import {ITALIAN_CITY} from 'src/constants/mock/city'

type CaregiverDetailDialogProps = {
  detail?: Record<string, any>;
  caregiverOptions?: {label: string, value: string}[];
  onClose: () => void;
  familyCardInfo?: boolean;
};

const DEFAULT_CAREGIVER_OPTIONS = [
  {
    label: 'Genitore',
    value: 'PARENT'
  },
  {
    label: 'Affidatario',
    value: 'CUSTODIAL'
  },
  {
    label: 'Tutore legale',
    value: 'LEGAL_TUTOR'
  }
]

const CaregiverDetailDialog: React.FC<CaregiverDetailDialogProps> = ({detail, onClose, caregiverOptions = DEFAULT_CAREGIVER_OPTIONS, familyCardInfo = false}) => {
  const [form] = useForm()

  useEffect(() => {
    form.setFieldValue('title', detail?.title)
    form.setFieldValue('lastName', detail?.lastName)
    form.setFieldValue('firstName', detail?.firstName)
    form.setFieldValue('code', detail?.code)
    form.setFieldValue('sex', detail?.sex)
    form.setFieldValue('nationality', detail?.nationality)
    form.setFieldValue('phoneNumber', detail?.phoneNumber)
    form.setFieldValue('cellPhoneNumber', detail?.cellPhoneNumber)
    form.setFieldValue('faxNumber', detail?.faxNumber)
    form.setFieldValue('email', detail?.email)
    form.setFieldValue('country', 'IT')
    form.setFieldValue('region', detail?.address?.region)
    form.setFieldValue('province', detail?.address?.province)
    form.setFieldValue('city', detail?.address?.city)
    form.setFieldValue('address', detail?.address?.address)
    form.setFieldValue('streetNumber', detail?.address?.streetNumber)
    form.setFieldValue('postalCode', detail?.address?.postalCode)
    form.setFieldValue('locality', detail?.address?.locality)
    form.setFieldValue('caregiverType', detail?.caregiverType)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const body = (
    <Form form={form} layout='vertical'>
      <CaregiverDetailDialogStyled>
        <div className='container'>
          <div className='container-title'>
            <Typography variant='h4'>
              <FormattedMessage id='mainData' />
            </Typography>
          </div>
          <Divider />
          <div className='container-body'>
            <div className='container-body-row-first'>
              <div className='container-body-element'>
                <Form.Item
                  label='Titolo'
                  name='title'
                >
                  <Select
                    allowClear
                    disabled
                    getPopupContainer={(trigger) => trigger}
                    options={[
                      {
                        label: 'Sig.',
                        value: 'signore'
                      },
                      {
                        label: 'Sig.na',
                        value: 'signora'
                      },
                      {
                        label: 'Sig.ra',
                        value: 'signorina'
                      },
                      {
                        label: 'Dott.',
                        value: 'dottore'
                      },
                      {
                        label: 'Dott.ssa',
                        value: 'dottoressa'
                      },
                      {
                        label: 'Prof.',
                        value: 'professore'
                      },
                      {
                        label: 'Prof.ssa',
                        value: 'professoressa'
                      }
                    ]}
                  />
                </Form.Item>
              </div>
              <div className='container-body-element'>
                <Form.Item
                  label='Cognome'
                  name='lastName'
                  required
                >
                  <Input disabled />
                </Form.Item>
              </div>
              <div className='container-body-element'>
                <Form.Item
                  label='Nome'
                  name='firstName'
                  required
                >
                  <Input disabled />
                </Form.Item>
              </div>
            </div>

            <div className={'container-body-row'}>
              {!familyCardInfo &&
                <div className='container-body-element'>
                  <Form.Item
                    label='Codice'
                    name='code'
                  >
                    <Input disabled />
                  </Form.Item>
                </div>}
              <div className='container-body-element'>
                <Form.Item
                  label='Tipo delegato'
                  name='caregiverType'
                  required
                >
                  <Select
                    allowClear
                    disabled
                    getPopupContainer={(trigger) => trigger}
                    options={caregiverOptions}
                  />
                </Form.Item>
              </div>
              <div className='container-body-element'>
                <Form.Item
                  label='Sesso'
                  name='sex'
                >
                  <Select
                    allowClear
                    disabled
                    getPopupContainer={(trigger) => trigger}
                    options={[
                      {
                        value: 'MALE',
                        label: 'Maschio'
                      },
                      {
                        value: 'FEMALE',
                        label: 'Femmina'
                      },
                      {
                        value: 'NOT_DECLARED',
                        label: 'Non dichiarato'
                      },
                      {
                        value: 'OTHER',
                        label: 'Altro'
                      }
                    ]}
                  />
                </Form.Item>
              </div>

              <div className='container-body-element'>
                <Form.Item
                  label='Nazionalità'
                  name='nationality'
                >
                  <Input disabled />
                </Form.Item>
              </div>

            </div>
          </div>
        </div>
        <div className='container'>
          <div className='container-title'>
            <Typography variant='h4'>
              <FormattedMessage id='contactsData' />
            </Typography>
          </div>
          <Divider />
          <div className='container-body'>

            <div className='container-body-row-grid'>
              <div className='container-body-element'>
                <Form.Item
                  label='Telefono'
                  name='phoneNumber'
                >
                  <Input disabled />
                </Form.Item>
              </div>
              <div className='container-body-element'>
                <Form.Item
                  label='Cellulare'
                  name='cellPhoneNumber'
                >
                  <Input disabled />
                </Form.Item>
              </div>

              <div className='container-body-element'>
                <Form.Item
                  label='Fax'
                  name='faxNumber'
                >
                  <Input disabled />
                </Form.Item>
              </div>

              <div className='container-body-element'>
                <Form.Item
                  label='Email'
                  name='email'
                >
                  <Input disabled />
                </Form.Item>
              </div>

              <div className='container-body-element'>
                <Form.Item
                  label='Stato'
                  name='country'
                >
                  <Select
                    disabled
                    getPopupContainer={(trigger) => trigger}
                    options={[{
                      value: 'IT',
                      label: 'Italia'
                    }]}
                  />
                </Form.Item>
              </div>

              <div className='container-body-element'>
                <Form.Item
                  label='Regione'
                  name='region'
                >
                  <Select
                    allowClear
                    disabled
                    getPopupContainer={(trigger) => trigger}
                    options={ITALIAN_REGIONS}
                    showSearch
                  />
                </Form.Item>
              </div>

              <div className='container-body-element'>
                <Form.Item
                  label='Provincia'
                  name='province'
                >
                  <Select
                    allowClear
                    disabled
                    getPopupContainer={(trigger) => trigger}
                    options={ITALIAN_PROVINCE}
                    showSearch
                  />
                </Form.Item>
              </div>

              <div className='container-body-element'>
                <Form.Item
                  label='Città'
                  name='city'
                >
                  <Select
                    allowClear
                    disabled
                    getPopupContainer={(trigger) => trigger}
                    options={ITALIAN_CITY}
                    showSearch
                  />
                </Form.Item>
              </div>
            </div>
            <div className='container-body-row container-body-row-long'>
              <div className='container-body-element'>
                <Form.Item
                  label='Indirizzo'
                  name='address'
                >
                  <Input disabled />
                </Form.Item>
              </div>

              <div className='container-body-element'>
                <Form.Item
                  label='Civico'
                  name='streetNumber'
                >
                  <Input disabled />
                </Form.Item>
              </div>

              <div className='container-body-element'>
                <Form.Item
                  label='Codice Postale'
                  name='postalCode'
                >
                  <Input disabled />
                </Form.Item>
              </div>

              <div className='container-body-element'>
                <Form.Item
                  label='Località'
                  name='locality'
                >
                  <Input disabled />
                </Form.Item>
              </div>
            </div>
          </div>
        </div>
      </CaregiverDetailDialogStyled>
    </Form>
  )

  return (
    <DialogCustom
      body={body}
      footer={<></>}
      maxWidth='lg'
      onClose={onClose}
      title={{text: `${detail?.title || ''} ${detail?.lastName || ''} ${detail?.firstName || ''}`, format: false}}
    />
  )
}

export default CaregiverDetailDialog
