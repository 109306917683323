/* eslint-disable react-hooks/exhaustive-deps */
import {useAppSelector} from 'src/hooks'
import UserData from 'src/components/templates/UserData/UserData'
import Form, {useForm} from 'antd/lib/form/Form'
import {useEffect, useState} from 'react'
import ContactData from 'src/components/templates/ContactData/ContactData'
import ProgramMembershipData from 'src/components/templates/ProgramMembershipData/ProgramMembershipData'
import {FormattedMessage} from 'react-intl'
import PrimaryButton from 'src/components/elements/Buttons/PrimaryButton/PrimaryButton'
import SecondaryButton from 'src/components/elements/Buttons/SecondaryButton/SecondaryButton'
import {ProfileStyle, SnackbarStyled} from './ProfileStyled'
import {Typography} from '@mui/material'
import Avatar from 'src/components/elements/Avatar/Avatar'
import UserAvatarDialog from 'src/components/templates/UserAvatarDialog/UserAvatarDialog'
import apiClient from 'src/services/apiClient'
import OthersData from 'src/components/templates/OthersData/OthersData'
import {setFormUser} from 'src/components/utils/formUtils'
import Icon from '@mdi/react'
import TabsCustom from 'src/components/layouts/TabsLayout/TabsLayout'
import {mdiRun, mdiFormatListBulleted} from '@mdi/js'
import TableCustom, {PaginationType} from 'src/components/elements/TableCustom/TableCustom'
import Filters, {FilterItem} from 'src/components/templates/Filters/Filters'
import ActivityDialog from 'src/components/templates/ActivityDialog/ActivityDialog'
import {STATUS, familyTypes} from 'src/constants/constants'
import SnackbarAlertCustom from 'src/components/elements/SnackbarAlertCustom/SnackbarAlertCustom'
import {dispatch} from 'src/redux-store'
import {restoreUserStatusPatch} from 'src/slices/userSlice'
import FamilyCard from 'src/components/templates/FamilyCard/FamilyCard'
import {Caregiver, Product} from 'src/types/types'
import PricingCard from 'src/components/templates/PricingCard/PricingCard'

export type UserType = {
  role: string,
  title: string,
  lastName: string,
  firstName: string,
  code?: string,
  sex?: string,
  nation?: string,
  insertDate?: string,
  specialization?: string,
  department?: string,
  birthDate?: string,
  doctor?: string,
  patologies?: Record<string, any>[],
  EMAIL?: boolean,
  SMS?: boolean,
  PUSH?: boolean,
  phoneNumber?: string,
  cellPhoneNumber?: string,
  faxNumber?: string,
  email?: string,
  skype?: string,
  country?: string,
  region?: string,
  province?: string,
  city?: string,
  address?: string,
  streetNumber?: string,
  postalCode?: string,
  locality?: string
}

const activitesStates = [
  {value: 'TO_BE_PLANNED', label: 'Da pianificare'},
  {value: 'TO_BE_CONFIRMED', label: 'Da confermare'},
  {value: 'TO_BE_CONCLUDED', label: 'Da concludere'},
  {value: 'TO_BE_VERIFIED', label: 'Da verificare'},
  {value: 'COMPLETED', label: 'Completata'},
  {value: 'CANCELLED', label: 'Cancellata'}
]

export type AvatarType = { imageBase64: string, _id: string }

const Profile = () => {
  const {profileConfiguration: {fields, activitiesTypes}} = useAppSelector(state => state.pspConfigReducer.data)
  const {data: userInfoData, statusPatch} = useAppSelector(({userReducer}) => userReducer)
  const [form] = useForm()
  const [open, setOpen] = useState(false)
  const [newAvatar, setNewAvatar] = useState<AvatarType>()
  const [newAvatarNickName, setNewAvatarNickName] = useState(userInfoData.avatarNickName)
  const [avatarImages, setAvatarImages] = useState([])
  const [selectedActivity, setSelectedActivity] = useState<any>()
  const [isActivityModalOpen, setIsActivityModalOpen] = useState(false)
  const [delegate1, setDelegate1] = useState<Record<string, any>>()
  const [delegate2, setDelegate2] = useState<Record<string, any>>()
  const [caregiver, setCaregiver] = useState<Record<string, any>>()
  const [timeslots, setTimeslots] = useState(userInfoData?.preferredTimeSlots ?? [])
  const [caregivers, setCaregivers] = useState<Caregiver[]>([])
  const [products, setProducts] = useState<Product[]>([])

  const [pagination, setPagination] = useState<PaginationType>({
    page: 0,
    rowsPerPage: 10,
    order: ''
  })
  const filterConfig: FilterItem[] = [
    {
      id: 'code',
      label: 'Codice attività',
      type: 'text'
    },
    {
      id: 'name',
      label: 'Nome',
      type: 'text'
    },
    {
      id: 'rangeDates',
      label: 'Data',
      type: 'rangePicker'
    },
    {
      id: 'state',
      label: 'Stato',
      type: 'select',
      options: activitesStates
    }
  ]
  const COLUMNS = [
    {id: 'group', label: 'GRUPPO'},
    {id: '_id', label: 'CODICE ATTIVITA'},
    {id: 'title', label: 'NOME'},
    {id: 'plannedDate', label: 'DATA/ORA', type: 'date', format: 'DD MMMM YYYY HH:mm'},
    {id: 'activityTypeReadable', label: 'TIPO'},
    {id: 'stateReadable', label: 'STATO'}
  ]

  const TABS = [
    {
      id: 'patientData',
      icon: <Icon path={mdiFormatListBulleted} size={1} />,
      label: <Typography style={{fontSize: 12, fontWeight: 600}}>{'Anagrafica'}</Typography>
    },
    {
      id: 'activities',
      icon: <Icon path={mdiRun} size={1} />,
      label: <Typography style={{fontSize: 12, fontWeight: 600}}>{'Attività'}</Typography>
    }
  ]

  const fetchData = async () => {
    if (!fields?.avatarId?.hidden) {
      const fetchedData = await apiClient.getAvatars() ?? []
      setAvatarImages(fetchedData)
      setNewAvatar(fetchedData.find((avatar: AvatarType) => avatar._id === userInfoData.avatarId))
    }
    if (fields?.familyCard && !fields?.familyCard?.hidden) {
      const data = await apiClient.getPatientsCaregivers(userInfoData._id, () => {})
      setCaregivers(data)
    }
    if (fields?.ceilings && !fields?.ceilings?.hidden) {
      const data = await apiClient.getPatientProducts(userInfoData._id)
      setProducts(data)
    }
  }

  useEffect(() => {
    fetchData()
  }, [userInfoData.avatarId, fields])

  useEffect(() => {
    setNewAvatarNickName(userInfoData.avatarNickName)
    setFormUser(form, userInfoData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, userInfoData])

  const handleOnSaveAvatar = async (avatarNickName: string, avatarSelected?: AvatarType) => {
    await apiClient.patchUserDataHttp({
      avatarId: avatarSelected?._id,
      avatarNickName: avatarNickName
    })

    setOpen(false)
  }

  const parseStates = (state: any) => {
    switch (state) {
      case 'TO_ACTIVE':
        return 'Da attivare'
      case 'ACTIVATING':
        return 'In attivazione'
      case 'ACTIVE':
        return 'Attivo'
      case 'ACTIVE_DELIVERY':
        return 'Attivo delivery'
      case 'SUSPENDED':
        return 'Sospeso'
      case 'WAITING_TO_EXIT':
        return 'In attesa di uscita'
      case 'EXITED':
        return 'Drop-out'
      default:
        return '-'
    }
  }

  const getParsedArray = (values: any, optionsKeys: string[]) => {
    const valuesKeys = Object.keys(values)

    const contains = optionsKeys.some(element => {
      return valuesKeys.includes(element)
    })
    if (!contains) {
      return undefined
    }

    const arrayValues: string[] = []
    optionsKeys.forEach((key: string) => {
      if (values[key]) {
        arrayValues.push(key)
      }
    })

    return arrayValues
  }

  const createPatchBody = (values: any) => {
    const patchValues = {
      ...values,
      birthDate: values.birthDate ? values.birthDate?.toISOString() : undefined,
      address: {
        region: (Object.keys(values).includes('region') ? (values.region || '') : undefined),
        province: (Object.keys(values).includes('province') ? (values.province || '') : undefined),
        city: (Object.keys(values).includes('city') ? (values.city || '') : undefined),
        country: values.country,
        address: values.address,
        streetNumber: values.streetNumber,
        postalCode: values.postalCode,
        locality: values.locality
      },
      caregiverId: caregiver?._id,
      delegateId_1: delegate1?._id,
      delegateId_2: delegate2?._id,
      preferredContacts: getParsedArray(values, ['EMAIL', 'SMS', 'PUSH']),
      receiveModuleDate: values.receiveModuleDate ? values.receiveModuleDate?.toISOString() : undefined,
      enrollmentDate: values.enrollmentDate ? values.enrollmentDate?.toISOString() : undefined,
      privacyConsents: getParsedArray(values, ['ENROLLMENT', 'PRIVACY_PHONE_SURVEY', 'PRIVACY_ENROLLMENT_APP_WEB']),
      drugDeliveryService: values.drugDeliveryService ? (values.drugDeliveryService === 'true') : undefined,
      sex: (Object.keys(values).includes('sex') ? (values.sex || '') : undefined),
      preferredTimeSlots: timeslots
    }

    const elementsToDelete = [
      'EMAIL', 'SMS', 'PUSH',
      'prescribingDoctor', 'medicalCenter', 'createdAt',
      'region', 'province', 'city', 'streetNumber', 'postalCode', 'locality', 'country',
      'ENROLLMENT', 'PRIVACY_PHONE_SURVEY', 'PRIVACY_ENROLLMENT_APP_WEB',
      'serviceConf'
    ]

    elementsToDelete.forEach(elem => {
      delete patchValues[elem]
    })

    Object.keys(patchValues).forEach(key => {
      if (patchValues[key] === undefined) {
        delete patchValues[key]
      }
    })

    return patchValues
  }

  const onFinish = async (values: any) => {
    const patchBody = createPatchBody(values)
    await apiClient.patchUserDataHttp(patchBody)
  }

  const onActivityClick = (activity: any) => {
    setSelectedActivity(activity)
    setIsActivityModalOpen(true)
  }

  const [activities, setActivities] = useState<Record<string, any>[]>([])
  const [filters, setFilters] = useState<Record<string, any>[]>([])
  const [activitiesCount, setActivitiesCount] = useState<number>(0)
  const [status, setStatus] = useState<string>()
  const [statusCount, setStatusCount] = useState<string>()

  useEffect(() => {
    const fetchActivitiesData = async () => {
      const fetchedData = await apiClient.getActivities(filters, setStatus, pagination) ?? []
      const parsedFetchData = fetchedData.map((activity: any) => {
        return {
          ...activity,
          stateReadable: activitesStates.find((option) => option.value === activity.state)?.label,
          activityTypeReadable: activitiesTypes.find((option: any) => option.value === activity.typeCode)?.label
        }
      })
      setActivities(parsedFetchData)
      const fetchedDataCount = await apiClient.getActivitiesCount(filters, setStatusCount) ?? []
      setActivitiesCount(fetchedDataCount)
    }

    if (userInfoData.userGroup.toUpperCase().includes('DOCTOR')) {
      fetchActivitiesData()
    }
  }, [filters, pagination])

  const onSubmit = (values: any) => {
    setFilters(values)
  }

  const detailInfo = (
    <Form
      form={form}
      layout='vertical'
      name='filter-form'
      onFinish={onFinish}
    >
      {fields.ceilings && !fields.ceilings?.hidden &&
        <PricingCard
          products={products}
        />}
      {fields.familyCard && !fields.familyCard?.hidden &&
        <FamilyCard
          caregivers={caregivers.filter((item) => Object.keys(familyTypes).includes(item.caregiverType))}
          readOnly={fields.familyCard?.readOnly}
        />}
      <UserData
        form={form}
        medicalCenters={userInfoData?.medicalCenters}
        patologies={userInfoData?.diseases}
        profileConfiguration={fields}
      />
      <ContactData
        caregiverValues={{caregiver, setCaregiver}}
        delegate1Values={{delegate1, setDelegate1}}
        delegate2Values={{delegate2, setDelegate2}}
        form={form}
        handleGetCity={() => undefined}
        handleGetProvince={() => undefined}
        handleGetRegion={() => undefined}
        preferredTimeSlots={timeslots}
        profileConfiguration={fields}
        setTimeslots={setTimeslots}
      />
      <ProgramMembershipData profileConfiguration={fields} />
      <OthersData profileConfiguration={fields} />
      <div style={{width: '100%', display: 'flex', justifyContent: 'flex-end', gap: '13px'}}>
        <PrimaryButton
          form='filter-form'
          key='submit'
          type='submit'
        >
          <FormattedMessage id='save' />
        </PrimaryButton>
        <SecondaryButton onClick={() => undefined}>
          <FormattedMessage id='cancel' />
        </SecondaryButton>
      </div>
    </Form>
  )

  const activites = (
    <>
      <div style={{display: 'flex', justifyContent: 'flex-end', marginBottom: '16px'}}>
        <Filters config={filterConfig} onSubmit={onSubmit} />
      </div>
      <TableCustom
        columns={COLUMNS}
        onPaginationChange={setPagination}
        onRowClick={onActivityClick}
        pagination={pagination}
        rows={activities}
        totalElements={activitiesCount}
      />
    </>
  )

  const CONTENT = [
    detailInfo,
    activites
  ]

  return (
    <ProfileStyle>
      <div className='layout-container-header'>
        {!fields?.avatarId?.hidden ?
          <>
            <Avatar disabled={fields?.avatarId?.readOnly} imageSrc={newAvatar?.imageBase64} onClick={() => setOpen(true)} selected={false} size='120px' />
            <div>
              <p style={{margin: 0}}>{newAvatarNickName}</p>
              <Typography style={{marginTop: 0, marginBottom: '20px'}} variant='h4'>{`${userInfoData.firstName} ${userInfoData.lastName}`}</Typography>
              <div className='label'>{`Stato: ${parseStates(userInfoData.state)}`}</div>
            </div>
          </> :
          <Typography variant='h4'>{`${userInfoData.firstName} ${userInfoData.lastName}`}</Typography>}
      </div>
      <div className='layout-container-body'>
        {userInfoData.userGroup.toUpperCase().includes('DOCTOR') ?
          <>
            <TabsCustom
              contents={CONTENT}
              tabs={TABS}
            />
            {isActivityModalOpen &&
              <ActivityDialog
                activity={{
                  ...selectedActivity
                }}
                onClose={() => setIsActivityModalOpen(false)}
                showDepartment={false}
                withDocument
              />}
          </> : (detailInfo)}
      </div>
      <SnackbarStyled
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        autoHideDuration={2000}
        onClose={() => { setStatus(undefined); setStatusCount(undefined) }}
        open={status === STATUS.REJECTED || statusCount === STATUS.REJECTED}
      >
        <div>
          <SnackbarAlertCustom
            onClose={() => { setStatus(undefined); setStatusCount(undefined) }}
            severity='error'
            sx={{width: '100%'}}
          >
            <Typography variant='h5'>
              {'Errore nel caricare le attività'}
            </Typography>
          </SnackbarAlertCustom>
        </div>
      </SnackbarStyled>
      <SnackbarStyled
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        autoHideDuration={2000}
        onClose={() => { dispatch(restoreUserStatusPatch()) }}
        open={statusPatch === STATUS.REJECTED}
      >
        <div>
          <SnackbarAlertCustom
            onClose={() => { dispatch(restoreUserStatusPatch()) }}
            severity='error'
            sx={{width: '100%'}}
          >
            <Typography variant='h5'>
              {'Errore nel modificare i dati'}
            </Typography>
          </SnackbarAlertCustom>
        </div>
      </SnackbarStyled>
      <SnackbarStyled
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        autoHideDuration={2000}
        onClose={() => { dispatch(restoreUserStatusPatch()) }}
        open={statusPatch === STATUS.FULFILLED}
      >
        <div>
          <SnackbarAlertCustom
            onClose={() => { dispatch(restoreUserStatusPatch()) }}
            severity='success'
            sx={{width: '100%'}}
          >
            <Typography variant='h5'>
              {'Modifica eseguita correttamente'}
            </Typography>
          </SnackbarAlertCustom>
        </div>
      </SnackbarStyled>
      {open && <UserAvatarDialog avatar={newAvatar} avatarImages={avatarImages} avatarNickName={newAvatarNickName} onClose={() => setOpen(false)} onSave={handleOnSaveAvatar} />}
    </ProfileStyle>
  )
}

export default Profile
